.testimonial {
  margin-bottom: 3rem;
}

.pagination {
  display: flex;
  justify-content: start;
  list-style: none;
  padding: 0;
}
.pagination li {
  margin: 0 5px;
}
.pagination li a {
  text-decoration: none;
}
.pagination li a.active {
  font-weight: bold;
}
